<template>
    <div>
        First
    </div>
</template>
<script>
export default {
    created() {
        console.log('first created');
    },
    mounted() {
        console.log('first mounted');
    },
    activated() {
        console.log('first activated');
    },
    deactivated() {
        console.log('first deactivated');
    },
    destroyed() {
        console.log('first destroyed');
    }
}
</script>
